<template>
  <v-card class="ma-2 pa-4">
    <v-card-text>
      <v-layout>
        <v-flex>
          <v-select label="Classroom" outlined dense hide-details></v-select>
        </v-flex>
        <v-flex>
          <v-select label="Student" outlined dense hide-details class="mx-2"></v-select>
        </v-flex>
        <v-spacer></v-spacer>
        <v-flex>
          <v-text-field label="search" outlined dense hide-details></v-text-field>
        </v-flex>
      </v-layout>
    </v-card-text>
    <v-card-text class="pt-0">
      <v-data-table v-model="selected" :headers="headers" :items="[]" item-key="name" show-select class="elevation-0">
        <template v-slot:top>
          <v-toolbar color="primary" dark flat v-if="selected.length">
            <v-flex lg6></v-flex>
            <v-select outlined dense hide-details label="Single select" class="pa-3"></v-select>
            <v-select outlined dense hide-details label="Single select" class="pa-3"></v-select>
            <v-btn label="Single select" class="pa-3"> Save Changes </v-btn>
          </v-toolbar>
        </template>
    </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data () {
    return {
      selected: [],
      headers: [
        { text: 'Roll No', value: 'number' },
        { text: 'Name', value: 'name' },
        { text: 'Class', value: 'class' },
        { text: 'Section', value: 'section' },
        { text: 'Actions', value: 'actions' }
      ]
    }
  }
}
</script>

<style>

</style>